<template>
	<div class="p-8">
		<card />
		<div class="flex h-80 pt-12">
			<img
				:src="`${baseURL}/img/homepage/${+homepageData}`"
				alt=""
				class="w-1/2 bg-slate-500"
			/>
			<p class="w-1/2">
				{{ this.removeTags(homepageText) }}
			</p>
		</div>
		<div class="flex h-80 mt-16">
			<p class="w-2/5">
				Lorem ipsum dolor, sit amet consectetur adipisicing elit.
				Dolorem obcaecati deleniti veritatis quis magnam. Labore nulla
				reprehenderit nobis commodi perferendis quisquam odio voluptas,
				ratione sequi dolorum, nam delectus accusantium inventore.
			</p>
			<img src="" alt="" class="w-3/5 bg-slate-500" />
		</div>
		<img src="" alt="" class="w-full mt-16" />
	</div>
</template>

<script>
import Card from "./Card.vue";
import axios from "axios";
import api, { BASE_URL } from "../helpers/auth-interceptor";

export default {
	components: {
		Card,
	},
	data() {
		return {
			baseURL: BASE_URL,
			homepageData: [],
			homepageText: [],
		};
	},
	mounted() {
		this.fetchCardData();
	},
	methods: {
		fetchCardData() {
			axios;
			api.get("/getHomepagejson")
				.then((response) => {
					console.log("homepage", response.data);
					this.homepageData = response.data[0].homepage_image_1;
					this.homepageText = response.data[0].homepage_text_1;
					// Handle the response from the backend
				})
				.catch((error) => {
					console.log(error);

					// Handle errors
				});
		},
		redirectToDetail(worker_id) {
			// Redirect to the detail page using the clicked card's ID
			window.location.href = `/profile/${worker_id}`;
			console.log(worker_id);
			//router.push({ name: "profile", params: { worker_id } });
		},
		removeTags(str) {
			if (str === null || str === "") return false;
			else str = str.toString();

			// Regular expression to identify HTML tags in
			// the input string. Replacing the identified
			// HTML tag with a null string.
			return str.replace(/(<([^>]+)>)/gi, "");
		},
	},
};
</script>
