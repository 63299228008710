<template>
	<div class="relative">
		<!-- Slider -->
		<slider></slider>
		<div class="flex flex-col px-8 gap-y-24">
			<div class="flex w-full">
				<div class="bg-red-500 z-10 -mt-32 ml-8 h-52 w-52">
					<img
						:src="`${baseURL}/img/avatars/${profileData?.worker_data?.[0]?.profile_img}`"
						alt=""
					/>
				</div>
				<span class="pl-4 pt-3 font-bold grow text-left">
					{{ profileData?.worker_data?.[0]?.hunter_name }} |
					{{ profileData.age }}
				</span>
				<a class="grow pt-3 font-bold">{{ profileData.phone }}</a>
			</div>
			<div class="flex pt-20">
				<div class="grow bg-slate-400">1</div>
				<div class="grow bg-green-400">2</div>
			</div>
			<div class="flex bg-gray-300">
				<h1>My Service</h1>
				<div class=""></div>
			</div>
		</div>
	</div>
</template>

<script>
import Slider from "./Slider.vue";
import api, { BASE_URL } from "../helpers/auth-interceptor";
export default {
	components: { Slider },
	data() {
		return {
			baseURL: BASE_URL,
			profileData: {},
		};
	},
	created() {
		this.fetchProfileData();
		console.log("created");
	},
	methods: {
		fetchProfileData() {
			const worker_id = this.$route.params.worker_id;
			api.get(`/getWorkerMetaDatajson?worker_id=${worker_id}`)
				.then((response) => {
					console.log("a");

					console.log("please", response);
					this.profileData = response.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
	},
};
</script>
