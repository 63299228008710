<template>
	<div>
		<Header />

		<router-view></router-view>
		<Content v-if="!isProfile" />
		<!-- <test /> -->
		<Footer />
	</div>
</template>

<script>
import Content from "./components/Content.vue";
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
import Test from "./components/Test.vue";

export default {
	name: "App",
	components: {
		Header,
		Footer,
		Content,
		Test,
	},
	computed: {
		isProfile() {
			return this.$route.name == "profile" || this.$route.name == "about";
		},
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
