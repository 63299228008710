<template>
	<GMapMap
		:center="{ lat: 51.093048, lng: 6.84212 }"
		:zoom="7"
		map-type-id="terrain"
		style="height: 250px"
	>
	</GMapMap>
</template>

<script >
export default {
	name: "App",
	data() {
		return {
			center: { lat: 53.073635, lng: 8.806422 },
		};
	},
};
</script>