<template>
	<div class="text-primary bg-black p-8">
		<div class="flex justify-between gap-4 mb-4 flex-col md:flex-row">
			<div class="w-1/4 text-left">
				<h2 class="mb-8 font-bold">Information</h2>
				<ul class="flex flex-col gap-4">
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
				</ul>
			</div>
			<div class="w-1/4 text-left">
				<h2 class="mb-8 font-bold">Kontakt</h2>
				<ul class="flex flex-col gap-4">
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
					<li><a href="">About Us</a></li>
				</ul>
			</div>
			<div class="flex-1 text-right">
				<h2 class="mb-8">Kontakt</h2>
				<Map />
			</div>
		</div>
		<span class="relative">All right reserved!</span>
	</div>
</template>

<script>
import Map from "./Map.vue";
export default {
	components: { Map },
	data() {
		return {
			posts: [],
		};
	},

	methods: {
		async getData() {
			try {
				const response = await this.$http.get(
					"http://jsonplaceholder.typicode.com/posts"
				);
				// JSON responses are automatically parsed.
				this.posts = response.data;
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
