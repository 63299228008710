<template>
	<nav class="h-32 bg-black text-white flex px-8 py-4">
		<router-link to="/"><span class="w-36"> Logo</span></router-link>
		<ul class="flex gap-8 items-end font-bold">
			<li><router-link to="/"> Home </router-link></li>
			<li><a href="#">New</a></li>
			<li><router-link to="/about"> About </router-link></li>
		</ul>
	</nav>
</template>

<script>
export default {};
</script>

<style>
</style>