import axios from 'axios';

export const BASE_URL = 'https://api.hausno7.de';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }
});

export default api;
