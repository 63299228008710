// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Profile from "../components/Profile.vue"; // Import your Profile component
import About from "../components/About.vue"; // Import your Profile component

const routes = [
    {
        path: "/profile/:worker_id",
        name: "profile", // Name for the route
        component: Profile, // Your Profile component
        props: true, // Allows passing props via route params
    },
    {
        path: "/login",
        name: "login", // Name for the route
        component: Profile, // Your Profile component
        props: true, // Allows passing props via route params
    },
    {
        path: "/about",
        name: "about", // Name for the route
        component: About, // Your Profile component
        props: true, // Allows passing props via route params
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
