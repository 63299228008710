<template>
	<carousel class="bg-red-300" :wrap-around="true">
		<slide class="c-slider" v-for="(slide, index) in images" :key="index">
			<div class="w-full">
				<img
					class="w-full"
					:src="slide.url"
					alt="Image {{ index + 1 }}"
				/>
			</div>
		</slide>

		<template #addons>
			<navigation />
		</template>
	</carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import axios from "axios";
import api, { BASE_URL } from "../helpers/auth-interceptor";

export default {
	name: "App",
	components: {
		Carousel,
		Slide,
		Navigation,
	},
	data() {
		return {
			baseURL: BASE_URL,
			cardData: [],
			images: [
				{ url: "https://picsum.photos/800/600/?image=1" },
				{ url: "https://picsum.photos/800/600/?image=2" },
				{ url: "https://picsum.photos/800/600/?image=3" },
				// Add more images here
			],
		};
	},
	mounted() {
		this.sliderImages();
	},

	methods: {
		sliderImages() {
			axios;
			api.get("/getWorkerGalleryImagesjson")
				.then((response) => {
					this.cardData = response.data;
					console.log(response.data);
					// Handle the response from the backend
				})
				.catch((error) => {
					console.log(error);

					// Handle errors
				});
		},
	},
};
</script>
<style>
.c-slider {
	height: calc(100vh - 250px);
}
@media only screen and (max-width: 600px) {
	.c-slider {
		height: calc(100vh - 500px);
	}
}
</style>