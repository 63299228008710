import { createApp } from 'vue'
import App from './App.vue'
import "./assets/tailwind.css";
import router from './router' // Import Vue Router
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App);
app.use(router);

app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCAIwTPctnSM2PWcbK6cMdlZaSgEYIKp5U',
        // language: 'de',
    },
});

app.mount('#app');
