<template>
	<div class="container my-12 mx-auto px-4 md:px-12">
		<div class="flex flex-wrap -mx-1 lg:-mx-4">
			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<span class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</span>
				<!-- END Article -->
			</div>
			<!-- END Column -->

			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<article class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</article>
				<!-- END Article -->
			</div>
			<!-- END Column -->

			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<article class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</article>
				<!-- END Article -->
			</div>
			<!-- END Column -->

			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<article class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</article>
				<!-- END Article -->
			</div>
			<!-- END Column -->

			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<article class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</article>
				<!-- END Article -->
			</div>
			<!-- END Column -->

			<!-- Column -->
			<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
				<!-- Article -->
				<article class="overflow-hidden rounded-lg shadow-lg">
					<a href="#">
						<img
							alt="Placeholder"
							class="block h-auto w-full"
							src="https://picsum.photos/600/400/?random"
						/>
					</a>
				</article>
				<!-- END Article -->
			</div>
			<!-- END Column -->
		</div>
	</div>
</template>