<template>
	<div class="flex flex-wrap justify-between gap-4">
		<div
			class="flex items-center justify-center bg-white shadow-lg rounded-lg cursor-pointer"
			v-for="item in cardData"
			:key="item.worker_id"
		>
			<div class="" @click="redirectToDetail(item.worker_id)">
				<div class="relative w-full lg:w-72 h-72">
					<img
						:src="
							`${this.getBaseURL()}/img/avatars/` +
							item.profile_img
						"
						alt="Card Image"
						onerror="this.style.display='none'"
						class="object-cover object-center w-full h-full"
					/>

					<span
						class="h-8 w-8 block border-2 absolute bottom-0 right-0 m-2 rounded-full"
						:class="item.is_online ? 'bg-green-500' : 'bg-red-500'"
					></span>
				</div>
				<div>
					<h2 class="text-xl font-semibold mb-2">
						{{ item.hunter_name }}
					</h2>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api, { BASE_URL } from "../helpers/auth-interceptor";
import router from "../router/index"; // Import your router instance from your project

export default {
	data() {
		return {
			baseURL: BASE_URL,
			cardData: [],
		};
	},
	mounted() {
		this.fetchCardData();
	},
	methods: {
		getBaseURL() {
			return this.baseURL;
		},
		fetchCardData() {
			api.get("/getAllWorkersjson")
				.then((response) => {
					this.cardData = response.data;
					// Handle the response from the backend
				})
				.catch((error) => {
					console.log(error);

					// Handle errors
				});
		},
		redirectToDetail(worker_id) {
			// Redirect to the detail page using the clicked card's ID
			//window.location.href = `/profile/${worker_id}`;
			console.log(worker_id);
			router.push({ name: "profile", params: { worker_id } });
		},
	},
};
</script>

<style>
</style>
